import React, {useEffect, useState} from 'react';
import {push, ref, remove, get, query, equalTo, child, update} from "firebase/database";
import {auth, database} from "./firebase";

const GuidanceInput = (props) => {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            console.log('props.urls', props.urls);
            if (props.urls.length !== 0) {
                return;
            }
            const communityId = props.communityId;
            const dbRef = ref(database, 'communitymentor');
            // const cQuery = query(dbRef, equalTo(communityId));
            await get(dbRef).then((snapshot) => {
                console.log(' mentor ', snapshot.val());
                snapshot.forEach((childSnapshot) => {
                    const val = childSnapshot.val();
                    console.log('mentor attributes', val.mentorurls);
                    if(val.communityId === communityId && val.mentorurls != undefined){
                        props.setUrls(val.mentorurls.split(";"));
                    }
                    console.log('mentor urls', props.urls);
                });
            })
        };

        fetchData();
    }, []);

    async function createMentorUrls() {

        const dataRef = ref(database, 'communitymentor');

        const communityId = props.communityId;

        // const dbRef = ref(database, `communitymentor`);
        //, orderByChild('communityId'), equalTo(communityId)
        await get(dataRef).then((snapshot) => {
            console.log('fffffff', snapshot);
            if (snapshot.exists()) {
                snapshot.forEach((childSnapshot) => {
                    const childKey = childSnapshot.key;
                    const childData = childSnapshot.val();
                    if (childData.communityId === communityId) {
                        const mentorRef = child(dataRef, childKey);
                        // remove(mentorRef)
                        //     .then(() => {
                        //         console.log('Data removed successfully.');
                        //     })
                        //     .catch((error) => {
                        //         console.error('Error removing data: ', error);
                        //     });
                        // Remove only the specific attribute
                        update(mentorRef, { "mentorurls": props.urls.join("; ") })
                            .then(() => {
                                console.log('Attribute removed successfully.');
                            })
                            .catch((error) => {
                                console.error('Error removing attribute: ', error);
                            });
                    }

                });

            } else {
                console.log('No data available.');
            }
        }).catch((error) => {
            console.error('Error fetching data: ', error);
        });

        // const data = {
        //     "communityId": communityId,
        //     "mentorurls": props.urls.join("; ")
        // }

        // push(dataRef, data).then(() => {
        //     // setMessage('Mentor created');
        //     // setTimeout(() => {
        //     //     // setMessage('');
        //     //     window.location.reload(); // Refresh the page
        //     // }, 2000); // Hide the message after 2 seconds and refresh the page
        // }).catch((error) => {
        //     // setMessage(`Failed to add emails: ${error.message}`);
        // });


        // console.log(data);

    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            if (!props.urls.includes(inputValue.trim())) {
                props.setUrls([...props.urls, inputValue.trim()]);
                setInputValue('');
            }
        }
    };

    const handleRemoveUrl = (url) => {
        props.setUrls(props.urls.filter((u) => u !== url));
    };

    return (
        <div className="tag-input">
            <ul className="tags-list">
                {props.urls.map((url, index) => (
                    <li key={index} className="tag">
                        {url}
                        <button type="button" className="remove-tag" onClick={() => handleRemoveUrl(url)}>
                            &times;
                        </button>
                    </li>
                ))}
            </ul>
            {props.urls.length != 0 &&
                <button className="btn-add" onClick={createMentorUrls}>
                    Save URLs
                </button>
            }
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder="Type each url for your mentor and press Enter"
            />
        </div>
    );
};

export default GuidanceInput;
