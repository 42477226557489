import React, {useEffect, useState} from 'react';
import {push, ref, remove, get, query, equalTo, child, update} from "firebase/database";
import {auth, database} from "./firebase";
// import './TagInput.css'; // Create a CSS file for styling

const TagInput = (props) => {
    // const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            console.log('props.tags', props.tags);
            if (props.tags.length !== 0) {
                return;
            }
            const communityId = props.communityId;
            const dbRef = ref(database, 'communitymentor');
            // const cQuery = query(dbRef, equalTo(communityId));
            await get(dbRef).then((snapshot) => {
                console.log(' mentor ', snapshot.val());
                snapshot.forEach((childSnapshot) => {
                    const val = childSnapshot.val();
                    console.log('mentor attributes', val.mentorattributes);
                    if(val.communityId === communityId && val.mentorattributes != undefined){
                        props.setTags(val.mentorattributes.split(";"));
                    }
                    console.log('mentor tags', props.tags);
                });
            })
        };

        fetchData();
    }, []);

    async function createMentorAttributes() {

        const dataRef = ref(database, 'communitymentor');

        const communityId = props.communityId;

        // const dbRef = ref(database, `communitymentor`);
        //, orderByChild('communityId'), equalTo(communityId)
        await get(dataRef).then((snapshot) => {
            console.log('fffffff', snapshot);
            if (snapshot.exists()) {
                snapshot.forEach((childSnapshot) => {
                    const childKey = childSnapshot.key;
                    const childData = childSnapshot.val();
                    if (childData.communityId === communityId) {
                        const mentorRef = child(dataRef, childKey);
                        // remove(mentorRef)
                        //     .then(() => {
                        //         console.log('Data removed successfully.');
                        //     })
                        //     .catch((error) => {
                        //         console.error('Error removing data: ', error);
                        //     });
                        update(mentorRef, { "mentorattributes": props.tags.join("; ") })
                            .then(() => {
                                console.log('Attribute removed successfully.');
                            })
                            .catch((error) => {
                                console.error('Error removing attribute: ', error);
                            });
                    }

                });

            } else {
                console.log('No data available.');
            }
        }).catch((error) => {
            console.error('Error fetching data: ', error);
        });

        // const data = {
        //     "communityId": communityId,
        //     "mentorattributes": props.tags.join("; ")
        // }
        //
        // push(dataRef, data).then(() => {
        //     // setMessage('Mentor created');
        //     // setTimeout(() => {
        //     //     // setMessage('');
        //     //     window.location.reload(); // Refresh the page
        //     // }, 2000); // Hide the message after 2 seconds and refresh the page
        // }).catch((error) => {
        //     // setMessage(`Failed to add emails: ${error.message}`);
        // });


        // console.log(data);

        // props.tags.map((tag, index) => {
        //
        //
        //
        // });
        //     emails.split(",").forEach((email) => {
        //         push(dataRef, {
        //             email: email.trim(),
        //             communityId: selectedId,
        //             communityName: selectedName
        //         }).then(() => {
        //             setMessage('Emails added successfully');
        //             setTimeout(() => {
        //                 setMessage('');
        //                 window.location.reload(); // Refresh the page
        //             }, 2000); // Hide the message after 2 seconds and refresh the page
        //         }).catch((error) => {
        //             setMessage(`Failed to add emails: ${error.message}`);
        //         });
        //
        //     });
        //
        //     // Send the email
        //     const emailSvc = new emailService();
        //     const toEmailList = emails.split(',').map(email => email.trim());
        //     await emailSvc.sendEmail(toEmailList, selectedName);
        //
        // } else {
        //     console.log('No user signed in.');
        // }


    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            if (!props.tags.includes(inputValue.trim())) {
                props.setTags([...props.tags, inputValue.trim()]);
                setInputValue('');
            }
        }
    };

    const handleRemoveTag = (tag) => {
        props.setTags(props.tags.filter((t) => t !== tag));
    };

    return (
        <div className="tag-input">
            <ul className="tags-list">
                {props.tags.map((tag, index) => (
                    <li key={index} className="tag">
                        {tag}
                        <button type="button" className="remove-tag" onClick={() => handleRemoveTag(tag)}>
                            &times;
                        </button>
                    </li>
                ))}
            </ul>
            {props.tags.length != 0 &&
                <button className="btn-add" onClick={createMentorAttributes}>
                    Save mentor attributes
                </button>
            }
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                placeholder="Type each attribute for your mentor and press Enter"
            />
        </div>
    );
};

export default TagInput;
